import {useQuery} from '@tanstack/react-query';
import {PaginatedBackendResponse} from '@common/http/backend-response/pagination-response';
import {
  DatabaseNotification,
  DatabaseNotificationData,
  DatabaseNotificationDataUser,
} from '@common/notifications/database-notification';
import {apiClient} from '@common/http/query-client';

const Endpoint = 'notifications';

export interface FetchUserNotificationsResponse
  extends PaginatedBackendResponse<DatabaseNotification> {
  //
}

interface Payload {
  perPage?: number;
}

export function useUserNotifications() {
  return useQuery({
    queryKey: useUserNotifications.key,
    queryFn: () => fetchUserNotifications(),
    refetchInterval: 60000,
  });
}

export function useAdminNotifications(payload?: Payload) {
  return useQuery({
    queryKey: ['admin-notifications'],
    queryFn: () => fetchAdminNotifiations(payload),
    refetchInterval: 60000,
  });
}

function fetchUserNotifications(
  payload?: Payload,
): Promise<{pagination: {data: DatabaseNotificationDataUser[]}}> {
  return apiClient
    .get('myNotifications')
    .then(response => response.data);
}

function fetchAdminNotifiations(
  payload?: Payload,
): Promise<{pagination: {data: DatabaseNotificationData[]}}> {
  return apiClient
    .get(Endpoint, {params: payload})
    .then(response => response.data);
}

useUserNotifications.key = [Endpoint];
