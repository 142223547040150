import {Footer} from '@common/ui/footer/footer';
import {MainNavbar} from '@app/main-navbar';
import {ReactNode, useEffect, useLayoutEffect} from 'react';
import {useScrollToTop} from '@common/ui/navigation/use-scroll-to-top';
import {AdHost} from '@common/admin/ads/ad-host';
import {useThemeSelector} from '@common/ui/themes/theme-selector-context';
import {closeDialog, openDialog} from '@common/ui/overlays/store/dialog-store';
import {useLocation} from 'react-router-dom';
import DownloadGuid from './downloadGuid';
// import {useSelectedLocale} from '@common/i18n/selected-locale';
//@ts-expect-error
import useGeoLocation from 'react-ipgeolocation';
import {useAuth} from '@common/auth/use-auth';
import BanDialog from './BanDialog';
import {useSelectedLocale} from '@common/i18n/selected-locale';
import {useChangeLocale} from '@common/i18n/change-locale';
import AntiAdBlock from './anti-ad-block';

interface Props {
  children: ReactNode;
}
export function SitePageLayout({children}: Props) {
  useScrollToTop();
  const {selectedTheme} = useThemeSelector();

  const changeLocale = useChangeLocale();

  const {user, isLoggedIn} = useAuth();

  const {localeCode} = useSelectedLocale();
  // console.log(locale);
  // console.log(localeCode);

  const location = useLocation();
  const locationC = useGeoLocation();

  //! OLD SCRIPT
  // useLayoutEffect(() => {
  //   const googleAdUrl =
  //     'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  //     if (!location.pathname.includes("admin")) {
  //       fetch(new Request(googleAdUrl))
  //         .then(response => {
  //           if (!response.ok) {
  //             throw new Error(`HTTP error! Status: ${response.status}`);
  //           }
  //           return response.text();
  //         })
  //         .catch(err => {
  //           console.error('Fetch Error:', err);
  //           if (err.message.includes('Failed to fetch')) {
  //             console.log('Request was blocked or failed');
  //             openDialog(AntiAdBlock);
  //           } else if (err.message.includes('CORS')) {
  //             console.log('Request blocked by CORS policy');
  //           } else {
  //             console.error('Other error:', err);
  //           }
  //         });
  //     }
  // }, []);

  //! NEW SCRIPT
  useLayoutEffect(() => {
    if (location.pathname.includes('admin')) return;

    let isHandled = false;
    const script = document.createElement('script');
    const url =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    // Timeout to avoid false positives from slow networks
    const timeoutId = setTimeout(() => {
      if (!isHandled) {
        isHandled = true;
        script.remove();
        console.log('Ad check timed out - likely network issue');
      }
    }, 10000); // Adjust timeout duration as needed

    script.src = url;

    script.onload = () => {
      if (!isHandled) {
        isHandled = true;
        clearTimeout(timeoutId);
        script.remove();
        console.log('Ad script loaded - no ad blocker detected');
      }
    };

    script.onerror = () => {
      if (!isHandled) {
        isHandled = true;
        clearTimeout(timeoutId);
        script.remove();
        console.log('Ad script blocked - ad blocker detected');
        openDialog(AntiAdBlock);
      }
    };

    document.head.appendChild(script);

    return () => {
      clearTimeout(timeoutId);
      script.remove();
    };
  }, []);

  const hasSeenGuid = localStorage.getItem('hasSeenGuid');
  useLayoutEffect(() => {
    if (!hasSeenGuid && locationC.country === 'IR') {
      openDialog(DownloadGuid);
    }
  }, [locationC]);

  useEffect(() => {
    if (localeCode !== user?.language && isLoggedIn) {
      changeLocale.mutate({locale: user?.language});
    }
    if (location.pathname !== '/contact') {
      if (user?.banned_at && isLoggedIn) {
        localStorage.setItem('expire_ban', user?.expire_at as string);
        openDialog(BanDialog);
      }
    } else {
      closeDialog(BanDialog);
    }
  }, [location.pathname]);

  return (
    <div className="-mt-24 flex flex-col">
      <MainNavbar />
      <div
        className={`flex-auto ${selectedTheme.is_dark ? 'bg-black' : 'bg-white'}`}
      >
        {/* <AdHost slot="general_top" className="py-24" /> */}
        <div
          className={`relative z-10 min-h-[1000px] ${location.pathname.includes('titles') ? '' : 'mx-124 pt-48 [@media(max-width:767px)]:mx-12 [@media(max-width:767px)]:pt-28'}`}
        >
          {children}
        </div>
        <AdHost slot="general_bottom" className="py-24" />
      </div>
      <Footer className="container mx-auto mt-48 flex-shrink-0 px-24" />
    </div>
  );
}
