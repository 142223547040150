import {Dialog} from '@common/ui/overlays/dialog/dialog';
import {DialogBody} from '@common/ui/overlays/dialog/dialog-body';
import {DialogHeader} from '@common/ui/overlays/dialog/dialog-header';
import {closeDialog} from '@common/ui/overlays/store/dialog-store';
import downloadGuideVid from './downloadGuideVid.mp4';
import {Link} from 'react-router-dom';

function DownloadGuid() {
  return (
    <Dialog>
      <DialogHeader
        onDismiss={() => {
          closeDialog(DownloadGuid);
          localStorage.setItem('hasSeenGuid', 'true');
        }}
      >
        راهنمای دانلود
      </DialogHeader>
      <DialogBody className="w-full">
        <p>!!توجه داشته باشید که این آموزش فقط مختص کاربران داخل ایران است!!</p>
        <div className="w-400">
          <video
            className="h-400 w-full object-cover"
            src={downloadGuideVid}
            controls
          ></video>
          <p>
            برای اطلاع از آخرین اخبار و اطلاعیه ها و بروزرسانی های سایت لطفاً در
            کانال تلگرامی MDBMAX مشترک شوید
          </p>
          <Link
            to={'https://t.me/MdbMax'}
            target="_blank"
            className="text-base text-[#24A1DE]"
          >
            آدرس کانل تلگرام
          </Link>
        </div>
      </DialogBody>
    </Dialog>
  );
}

export default DownloadGuid;
