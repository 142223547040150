import {IconButton} from '../../ui/buttons/icon-button';
import {NotificationsIcon} from '../../icons/material/Notifications';
import {useUserNotifications} from './requests/user-notifications';
import {ProgressCircle} from '../../ui/progress/progress-circle';
import {NotificationList} from '../notification-list';
import {DialogTrigger} from '../../ui/overlays/dialog/dialog-trigger';
import {Dialog} from '../../ui/overlays/dialog/dialog';
import {DialogHeader} from '../../ui/overlays/dialog/dialog-header';
import {DialogBody} from '../../ui/overlays/dialog/dialog-body';
import {Trans} from '../../i18n/trans';
import {Badge} from '../../ui/badge/badge';
import {useMarkNotificationsAsRead} from '../requests/use-mark-notifications-as-read';
import {NotificationEmptyStateMessage} from '../empty-state/notification-empty-state-message';
import {useEffect, useState} from 'react';

interface NotificationDialogTriggerProps {
  onClick?: () => void;
  className?: string;
}

export function NotificationDialogTrigger({
  onClick,
  className,
}: NotificationDialogTriggerProps) {
  const query = useUserNotifications();
  const markAsRead = useMarkNotificationsAsRead();
  const {data, isLoading} = useUserNotifications();
  //@ts-expect-error
  const [notificationList, setNotificationList] = useState(data?.notifications);

  const handleRead = (id: number) => {
    const cloneNotifs = [...notificationList];
    const findTheIndex = cloneNotifs.findIndex(item => item.id === id);
    const findObject = {...cloneNotifs[findTheIndex]};
    findObject.is_read = 1;
    cloneNotifs[findTheIndex] = findObject;
    setNotificationList(cloneNotifs);
  };

  useEffect(() => {
    //@ts-expect-error
    setNotificationList(data?.notifications);
  }, [data]);

  return (
    <DialogTrigger type="popover">
      <IconButton
        size="md"
        onClick={onClick}
        className={className}
        badge={
          // hasUnread ? (
          //@ts-expect-error
          notificationList?.some(notif => notif.is_read === 0) && (
            <Badge withBorder={false} className="max-md:hidden">
              {
                //@ts-expect-error
                notificationList?.filter(notif => notif.is_read === 0).length
              }
            </Badge>
          )
          // ) : undefined
        }
      >
        <NotificationsIcon size="32" />
      </IconButton>
      <Dialog>
        <DialogHeader>
          <Trans message="Notifications" />
        </DialogHeader>
        <DialogBody padding="p-0">
          <DialogContent
            data={data}
            isLoading={isLoading}
            onReadResponse={handleRead}
          />
        </DialogBody>
      </Dialog>
    </DialogTrigger>
  );
}

//@ts-expect-error
function DialogContent({data, isLoading, onReadResponse}) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center px-24 py-20">
        <ProgressCircle aria-label="Loading notifications..." isIndeterminate />
      </div>
    );
  }
  if (!data?.notifications.length) {
    return (
      <div className="px-24 py-20">
        <NotificationEmptyStateMessage />
      </div>
    );
  }
  return (
    <div>
      <NotificationList
        notifications={data?.notifications}
        onReadResponse={onReadResponse}
      />
    </div>
  );
}
