import {Star} from 'iconsax-react';
import addthedns from '../../public/assets/images/guid-download/addthedns.png';
import clickonproperties from '../../public/assets/images/guid-download/clickonproperties.png';
import gotosetting from '../../public/assets/images/guid-download/gotosetting.png';
import selecthangeadaptersetting from '../../public/assets/images/guid-download/selectchangeadaptersetting.png';
import selectipv4 from '../../public/assets/images/guid-download/selectipv4andproperties.png';
import androidwifilist from '../../public/assets/images/guid-download/android-wifi-list.jpg';
import androidadvanceoption from '../../public/assets/images/guid-download/android-wifi-advanced-options.jpg';
import androiddhcp from '../../public/assets/images/guid-download/android-wifi-select-dhcp-options.jpg';
import androidipstatic from '../../public/assets/images/guid-download/android-wifi-select-dhcp-options-static.jpg';
import androidsetdns from '../../public/assets/images/guid-download/android-wifi-set-dns-server.jpg';
import {Link} from 'react-router-dom';

function DownloadGuidPage() {
  return (
    <div className="mt-80 space-y-20">
      <div className="flex items-center gap-x-[10px]">
        <Star size={30} color="#fff" />
        <h5 className="m-0 p-0">چند نکته ضروری برای استفاده بهتر از سایت</h5>
      </div>
      <p>
        برای دانلود، ابتدا DNS های خود را مطابق تصاویر زیر تغییر دهید تا نیازی
        به استفاده از فیلترشکن نداشته باشید.
      </p>
      <p>
        برای این کار ابتدا به بخش تنظیمات رفته و از منوی تنظیمات گزینه
        <strong>Network & Internet</strong> را انتخاب کنید
      </p>
      <img src={gotosetting} alt="" />
      <p>
        سپس از بخش <strong>Advance Network Setting</strong> گزینه{' '}
        <strong>Change adapter options</strong> را انتخاب کنید
      </p>
      <img src={selecthangeadaptersetting} alt="" />
      <p>
        پس از آنکه شبکه ای که به آن متصل هستید را انتخاب کردید پنجره ای برای شما
        باز خواهد شد بر روی دکمه <strong>Properties</strong> کلیک کنید
      </p>
      <img src={clickonproperties} alt="" />
      <p>
        سپس بر روی <strong>Internet Protocol Version 4</strong> کلیک کنید و پس
        از آن <strong>Properties</strong> را انتخاب کنید
      </p>
      <img src={selectipv4} alt="" />
      <p>
        در پنجره باز شده در بخش مشخص شده در تصویر ابتدا گزینه{' '}
        <strong>Use the following DNS addresses</strong> انتخاب کنید و طبق تصویر
        در فیلد اول <strong>🔹 178.22.122.100</strong> و در فیلد دوم{' '}
        <strong>🔹 185.51.200.2</strong> را وارد کنید و ذخیره کنید
      </p>
      <img src={addthedns} alt="" />
      <p>از دانلود لذت ببرید</p>
      <div className="flex items-center gap-x-[10px]">
        <Star size={30} color="#fff" />
        <h5 className="m-0 p-0">تنظیمات dns در اندروید</h5>
      </div>
      <p>برای تنظیم dns بر روی اندروید مراحل زیر را انجام دهید</p>
      <p>
        در <strong>Setting</strong> به بخش <strong>ٌWifi</strong> بروید
      </p>
      <p>
        بر روی شبکه وای فایی که میخواهید dns را بر روی آن تنظیم کنید نگه دارید
      </p>
      <p>
        در باکس کوچک باز شده گزینه <strong>Modify network</strong> را انتخاب
        کنید
      </p>
      <img className="max-h-[500px]" src={androidwifilist} alt="" />
      <p>
        بر روی <strong>Andvance options</strong> کلیک کنید
      </p>
      <img className="max-h-[500px]" src={androidadvanceoption} alt="" />
      <p>
        بر روی <strong>DHCP</strong> در <strong>ip setting</strong> کلیک کنید
      </p>
      <img className="max-h-[500px]" src={androiddhcp} alt="" />
      <p>
        گزینه <strong>Static</strong> را انتخاب کنید
      </p>
      <img className="max-h-[500px]" src={androidipstatic} alt="" />
      <p>
        حال در فیلد <strong>DNS1</strong> مقدار{' '}
        <strong>🔹 178.22.122.100</strong> و در فیلد <strong>DNS2</strong> مقدار{' '}
        <strong>🔹 185.51.200.2</strong> را وارد کنید
      </p>
      <img className="max-h-[500px]" src={androidsetdns} alt="" />
      <p>از دانلود لذت ببرید</p>
      <div className="flex items-center gap-x-[10px]">
        <Star size={30} color="#fff" />
        <h5 className="m-0 p-0">نکات مهم دیگر</h5>
      </div>
      <p>
        {' '}
        پس از دانلود، در صورت تمایل می‌توانید تنظیمات را به حالت اولیه
        بازگردانید.
      </p>
      <p>
        برای تغییر DNS در گوشی همراه، مراحل مشخص‌ شده در تصویر را دنبال کنید.
      </p>
      <p>
        جهت دانلود سریع‌تر و آسان‌تر پیشنهاد می‌شود از نرم‌افزار IDM (Internet
        Download Manager) استفاده کنید.
      </p>
      <p>لینک‌های موردنیاز برای دانلود نرم‌افزار در ادامه ارائه شده است.</p>
      <Link
        to={
          'https://soft98.ir/internet/download-manager/4-internet-download-manager-4.html'
        }
        target="_blank"
      >
        لینک دانلود نرم افزار IDM
      </Link>
      <p>لطفاً فراموش نکنید که افزونه IDM را در مرورگر خود فعال کنید.</p>
      <p>
        در صورتی که فیلم یا سریالی لینک دانلود نداشته باشد، دلیل آن حجم گسترده
        محتوای سایت است. بررسی تک‌تک ویدیوها زمان زیادی می‌طلبد، و همچنین ممکن
        است برخی از محتواها با فرهنگ ایرانی همخوانی نداشته باشند. ازاین‌رو،
        گزینه‌ای برای فعال‌سازی محتوا در نظر گرفته شده است تا پس از درخواست شما،
        تیم مدیریت محتوا آن را بررسی کرده و در صورت تأیید، لینک دانلود را فعال
        نماید.
      </p>
      <p>
        همچنین، در چنین مواردی شما می‌توانید محتوای موردنظر را در لیست پیگیری
        خود قرار دهید تا در صورت انتشار، از طریق نوتیفیکیشن به شما اطلاع داده
        شود.
      </p>
      <div className="flex items-center gap-x-[10px]">
        <Star size={30} color="#fff" />
        <h5 className="m-0 p-0">نکته‌ای درباره انتظارات کاربران</h5>
      </div>
      <p>
        برخی کاربران بدون آگاهی از سختی‌های مدیریت یک سایت، قضاوت‌های نادرستی
        می‌کنند و تصور دارند که فرآیندها پیچیده شده است، درحالی‌که حتی زمانی
        برای مطالعه اطلاعیه‌ها و نوتیفیکیشن‌های سایت اختصاص نمی‌دهند.
      </p>
      <p>
        لازم به ذکر است که این سایت یک انجمن آموزش اینترنت و کامپیوتر نیست و
        کاربران می‌بایست مفاهیم ابتدایی مرتبط با فضای مجازی را از منابع معتبر
        بیاموزند. متأسفانه برخی از این کاربران، بدون رعایت ادب و احترام،
        درخواست‌ها یا گزارش‌های خود را ارسال می‌کنند. درحالی‌که رسالت ما، آموزش
        اصول اخلاقی و رفتاری نیست.
      </p>
      <div className="flex items-center gap-x-[10px]">
        <Star size={30} color="#fff" />
        <h5 className="m-0 p-0">درباره تیم مدیریت سایت</h5>
      </div>
      <p>
        ما در طول این سال‌ها با افرادی مواجه شده‌ایم که نه‌تنها هرگز این سایت را
        با دیگران به اشتراک نگذاشته‌اند، بلکه همیشه انتظار بیشتری داشته‌اند.
      </p>
      <p>
        در راستای بهبود خدمات، تیم "به نام ایران" با تیم MdbMax ادغام شده است تا
        تجربه بهتری را در اختیار کاربران عزیز قرار دهد.
      </p>
      <div className="flex items-center gap-x-[10px]">
        <Star size={30} color="#fff" />
        <h5 className="m-0 p-0">حمایت از سایت و آینده آن</h5>
      </div>
      <p>
        این سایت، مخاطبان وفادار بسیاری دارد که چه در قالب اشتراک‌های ویژه و چه
        به‌صورت رایگان، همواره از آن حمایت کرده‌اند. با این حال، ادامه فعالیت و
        ارائه خدمات رایگان نیازمند حمایت بیشتر کاربران است. درصورتی‌که حمایت
        کاربران کافی نباشد و سایت به اشتراک گذاشته نشود، ناچار خواهیم شد دسترسی
        عمومی را محدود کرده و خدمات را صرفاً برای کاربران ویژه (VIP) ارائه کنیم.
      </p>
      <p>
        هدف ما این است که سایت برای همه در دسترس باشد، اما بدون حمایت و
        اشتراک‌گذاری آن توسط کاربران، این امکان فراهم نخواهد بود. اگر تعداد
        کاربران حامی کاهش یابد، سایت به‌صورت اشتراکی ماهیانه ارائه خواهد شد و
        تنها اعضای ویژه به محتوای آن دسترسی خواهند داشت. در این صورت، تبلیغات
        حذف شده و کیفیت خدمات برای کاربران VIP افزایش خواهد یافت.
      </p>
      <p>
        بنابراین، حمایت شما از طریق اشتراک‌گذاری سایت با دیگران و مشارکت در
        توسعه آن، نقش مهمی در حفظ دسترسی عمومی و رایگان آن خواهد داشت.
      </p>
      <p>از همراهی و حمایت شما سپاسگزاریم.</p>
    </div>
  );
}

export default DownloadGuidPage;
