import {useSelectedLocale} from '@common/i18n/selected-locale';
import {Dialog} from '@common/ui/overlays/dialog/dialog';
import {DialogBody} from '@common/ui/overlays/dialog/dialog-body';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import {DialogHeader} from '@common/ui/overlays/dialog/dialog-header';
import { useEffect } from 'react';

function AntiAdBlock() {
  
  const {locale} = useSelectedLocale();

  return (
    <Dialog neverClosing>
      <DialogHeader hideDismissButton>
        {locale.language === 'fa'
          ? 'مسدود کننده تبلیغ روشن است'
          : 'Adblock is on'}
      </DialogHeader>
      <DialogBody>
        {locale.language === 'fa' ? (
          <div>
            <p>سلام دوست عزیز،</p>
            <p className='text-justify'>
              ما به عنوان تیم سایت، همیشه در تلاش هستیم تا بهترین تجربه را برای
              شما فراهم کنیم. اما باید به اطلاع شما برسانیم که طبق سیاست‌های
              شرکت هاستینگ ما، برای تأمین هزینه‌های مربوط به دیتای زیاد سایت،
              مجبور به نمایش تبلیغات هستیم.
            </p>
            <p className='text-justify'>
              در صورتی که تبلیغات مسدود شوند، مجبور خواهیم شد هزینه‌های قابل
              توجهی را به هاستینگ پرداخت کنیم که با توجه به حجم بالای سایت، قادر
              به تأمین آن‌ها نیستیم.
            </p>
            <p className='text-justify'>
              خواهشمندیم که برای حمایت از سایت و ادامه‌ی خدمات رایگان، اجازه
              دهید تبلیغات به درستی نمایش داده شوند. با حمایت شما، می‌توانیم
              همچنان به ارائه خدمات با کیفیت ادامه دهیم.
            </p>
            <p>با تشکر از همکاری شما،</p>
            <p>تیم MdbMax</p>
          </div>
        ) : (
          <div>
            <p>Hello dear user,</p>
            <p className='text-justify'>
              As the site team, we are always striving to provide you with the
              best possible experience. However, we would like to inform you
              that according to the policies of our hosting company, we are
              required to display ads to cover the costs associated with the
              high data volume of the site.
            </p>
            <p className='text-justify'>
              If the ads are blocked, we will be forced to pay significant fees
              to the hosting company, which, given the large volume of data on
              the site, we are unable to afford.
            </p>
            <p className='text-justify'>
              We kindly ask for your support in allowing the ads to be displayed
              correctly, so that we can continue offering free services. With
              your support, we can keep providing high-quality services.
            </p>
            <p>Thank you for your cooperation,</p>
            <p>The MdbMax Team</p>
          </div>
        )}
      </DialogBody>
    </Dialog>
  );
}

export default AntiAdBlock;
