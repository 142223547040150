import {JSXElementConstructor, useState} from 'react';
import {GroupAddIcon} from '../icons/material/GroupAdd';
import {PeopleIcon} from '../icons/material/People';
import {FileDownloadDoneIcon} from '../icons/material/FileDownloadDone';
import {DatabaseNotificationDataUser} from './database-notification';
import {SvgIconProps} from '../icons/svg-icon';
import clsx from 'clsx';
import {useNavigate} from '../utils/hooks/use-navigate';
import {Trans} from '@common/i18n/trans';
import {Eye} from 'iconsax-react';
import {useMarkAsRead} from './requests/use-mark-as-read';
import {Button} from '@common/ui/buttons/button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const iconMap = {
  'group-add': GroupAddIcon,
  people: PeopleIcon,
  'export-csv': FileDownloadDoneIcon,
} as Record<string, JSXElementConstructor<SvgIconProps>>;

interface NotificationListProps {
  notifications: DatabaseNotificationDataUser[];
  className?: string;
  onReadResponse?: (id: number) => void;
}
export function NotificationList({
  notifications,
  className,
  onReadResponse,
}: NotificationListProps) {
  const navigate = useNavigate();

  const markAsRead = useMarkAsRead();

  const [page, setPage] = useState<number>(1);

  const [notificationList, setNotificationList] = useState(notifications);

  const handleRead = (id: number) => {
    const cloneNotifs = [...notificationList];
    const findTheIndex = cloneNotifs.findIndex(item => item.id === id);
    const findObject = {...cloneNotifs[findTheIndex]};
    findObject.is_read = 1;
    cloneNotifs[findTheIndex] = findObject;
    setNotificationList(cloneNotifs);
    markAsRead.mutate(id as number, {
      onSuccess: () => {
        if (onReadResponse) {
          onReadResponse(id);
        }
      },
    });
  };

  return (
    <div className={clsx('max-h-[450px] overflow-y-auto', className)}>
      {notificationList?.slice(0, page * 20)?.map((notification, index) => {
        const isLast = index === notificationList.length - 1;
        return (
          <div
            className={clsx(
              'flex items-center justify-between px-6 py-10',
              !isLast && 'border-b',
            )}
          >
            <div
              className="flex w-4/5 cursor-pointer items-center gap-x-[12px]"
              onClick={() => {
                if (notification.url_id) {
                  navigate(`/titles/${notification.url_id}/data`);
                }
              }}
            >
              {notification.image && (
                <LazyLoadImage
                  className="w-[100px] object-cover"
                  src={notification.image}
                  alt=""
                  effect='blur'
                />
              )}
              <div className={'flex flex-col gap-[5px]'} key={notification.id}>
                <span className="text-base font-bold">
                  <span className="mx-[5px]">
                    <Trans message={notification.title as string} />
                  </span>
                  {notification.url_id && (
                    <span>
                      <Trans message="has been updated" />
                    </span>
                  )}
                </span>
                <span className="text-justify">
                  <Trans message={notification.short as string} />
                </span>
                <span className="text-justify">
                  <Trans message={notification.long as string} />
                </span>
              </div>
            </div>
            {notification.is_read === 0 && (
              <Eye
                className={`cursor-pointer ${markAsRead.isPending && 'text-toast'}`}
                size="24"
                color="#fff"
                onClick={() => handleRead(notification.id)}
              />
            )}
          </div>
        );
      })}
      {notificationList.length > page * 20 && (
        <div className="text-center">
          <Button onClick={() => setPage(prev => prev + 1)}>
            <Trans message="Load More" />
          </Button>
        </div>
      )}
    </div>
  );
}
